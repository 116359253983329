@import 'src/styles/common';


.mat-select {
  border: 1px solid $gray-card-stroke;
  height: 56px;
  border-radius: 8px;
  padding: 18px 16px;
  width: 100%;
  background: $white;

  &:focus {
    border: 1px solid $primary-blue;
  }

  &.has-error,
  &.ng-invalid.ng-touched,
  &.ng-invalid.ng-touched input {
    background: rgba(211, 47, 47, 0.1);
    border: 1px solid $error-red;
  }
}

.mat-select-panel-wrap {


  .mat-select-panel {
    margin-top: -20px;
    border-radius: 8px;
    border: 1px solid $gray-card-stroke;

    .mat-option {
      border-bottom: 1px solid $gray-card-stroke;

      &:last-child {
        border-bottom: 0;
      }
    }

    &:not(.multiple) .mat-option {
      &:hover,
      &.mat-selected,
      &.mat-active {
        background: $primary-blue;

        .mat-option-text {
          color: $white;
        }
      }
    }
  }

  .mat-pseudo-checkbox-checked {
    background: $swift-blue;
  }
}

