/* You can add global styles to this file, and also import other style files */

$material-icons-font-path: '~material-icons/iconfont/';
@import '~material-icons/iconfont/material-icons.scss';
@import "./styles/global.scss";

@import "~@swiftcore/ui-shared/css/icons";
@import '~@angular/cdk/overlay-prebuilt.css';
@import '~tippy.js/dist/tippy.css';
@import '~tippy.js/themes/material.css';
@import '~quill/dist/quill.core.css';

* {
  // needed because of chrome making elements disappear until hovered.
  // tricky to reproduce but relevant SO post:
  // https://stackoverflow.com/questions/11002195/chrome-does-not-redraw-div-after-it-is-hidden
  // -webkit-transform: scale3d(1,1,1);
  // have to use backface visibility because transform breaks the chatbot
  backface-visibility: visible;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

// Needs to be scoped outside of the :host due to the "portaling" of the panel to the top of the DOM
.mat-select-panel.state-selector-panel {
  width: 76px;
  max-width: 76px;
  // Causes a flash of wider content. Need to override better
  min-width: auto !important;
}

.mat-select-panel.date-day-selector-panel {
  width: 89px;
  max-width: 89px;
  // Causes a flash of wider content. Need to override better
  min-width: auto !important;
}

.mat-select-panel.other-beneficiaries-panel {
  transform: translateX(23px) !important;
  width: calc(100% + 32px) !important;
  max-width: calc(100% + 32px) !important;
  min-width: calc(100% + 32px) !important;
}

// Tippy.js styling
.tippy-box {
  // !important necessary due to inline styling applied by tippy.js
  max-width: 240px !important;
  padding: 14px 18px 20px;
}

.tippy-content {
  padding: 0;
  font-weight: 400;
  color: $white;
  font-size: 16px;
  line-height: 21px;
}
