// Commonly used styles for the onboarding pages
// Will likely have a larger wrapper which can utilize these once its hooked up
@import "src/styles/common";

.onboarding-page-container {
  margin: 0 auto;
  max-width: 1100px;
  margin-bottom: 48px;

  &.padding {
    padding-left: 24px;
    padding-right: 24px;
  }

  // Elements
  h2 {
    font-family: Assistant;
    font-style: normal;
    font-size: 41px;
    line-height: 54px;
    margin: 0;
  }

  h3 {
    font-family: Assistant;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    padding: 0;
    margin: 0;
    padding-bottom: 12px;
  }

  // Classes
  .header {
    padding: 16px 16px 32px 0px;

    p {
      font-family: Assistant;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      margin: 0;
      padding: 0;
    }

    @media (max-width: $breakpoint-md) {
      padding: 16px;
    }
  }

  .card {
    @include card();
    background: $white;
    padding: 16px;
    width: 100%;
    @media (min-width: $breakpoint-md) {
      &:not(:first-child) {
        margin-top: 24px;
      }
    }

    @media (max-width: $breakpoint-sm) {
      border: 0;
      border-radius: 0;
      margin-top: 0;
      &:not(:first-child) {
        margin-top: 12px;
      }
    }

    .total-price {
      font-family: Assistant;
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 42px;
    }
  }

  .purchase-details .wizard-mobile {
    font-family: Assistant;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    align-items: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $dark-gray;
    padding-bottom: 16px;
  }

  .button {
    @include card-button();
    width: auto;
    margin: 8px;
    margin-left: 0;
    cursor: pointer;
  }

  .overview-item {
    padding: 4px 0;

    .bold {
      font-weight: bold;
      border: 0;
      font-size: 16px;
      line-height: 21px;
      padding: 0;
      margin: 0;
      padding-bottom: 12px;
    }
  }

  // Helpers
  .border-bottom {
    width: 100%;
    border-bottom: 1px solid $gray-card-stroke;
  }

  // Tweaks
  .purchase-details,
  .total {
    margin: 8px 0;

    p {
      font-family: Assistant;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 21px;
      margin: 0;
      padding: 0;
    }
  }
}
